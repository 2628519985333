// components/MainLayout.js
import React, { useState, useContext  } from 'react';
import Header from '../Header/Header';
import NavBar from '../NavBar/NavBar';
import { ThemeContext } from '../../theme-context';

const MainLayout = ({ children }) => {
  
  const { theme } = useContext(ThemeContext);
  const [navbarOpen, setNavbarOpen] = useState(() => {
    const navbarState = localStorage.getItem('navbarState');
    return navbarState ? JSON.parse(navbarState) : false;
  });

  return (
    <div className={`body ${theme === 'light' ? '': 'dark'}`}>
      <Header navbarOpen={navbarOpen} setNavbarOpen={setNavbarOpen} />
      <NavBar navbarOpen={navbarOpen}/>
      {children}
    </div>
  );
};

export default MainLayout;
