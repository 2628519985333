import React, { useContext, useState, useEffect } from "react";
import { ThemeContext } from '../../theme-context';
import { SyncLoader } from 'react-spinners';
import './Card.css'
import './HomeCard.css'

const HomeDescriptionCard = ({ body, isLoading, waiting, onRefresh }) => {
  const { theme } = useContext(ThemeContext);
  
  const renderTextWithBold = (text) => {
    if (typeof text !== 'string') {
      return null;
    }
  
    const paragraphs = text.split(/\r\n|\n/);
    const processedParagraphs = paragraphs.map((paragraph, paragraphIndex) => {
      if (paragraph.trim() === '') {
        return <br key={paragraphIndex} />;
      }
      const words = paragraph.split(' ');
      const processedWords = words.map((word, wordIndex) => {
        let spaceAfter = ' ';
  
        // Check if next word starts with punctuation, if so, do not add a space
        if (wordIndex < words.length - 1 && /^[.,;:!?']/.test(words[wordIndex + 1])) {
          spaceAfter = '';
        }
  
        // If word is surrounded by '<' and '>', make it bold and remove the special characters
        if (word.startsWith('**') && word.endsWith('**')) {
          return <br />;
        }
        if (word.startsWith('<<<') && word.endsWith('>>>')) {
          return <strong key={`${paragraphIndex}-${wordIndex}`} style={{color: '#81b1d2'}}>{word.slice(3, -3)}{spaceAfter}</strong>;
        }
        if (word.startsWith('<<') && word.endsWith('>>')) {
          return <strong key={`${paragraphIndex}-${wordIndex}`} style={{color: '#fa8174'}}>{word.slice(2, -2)}{spaceAfter}</strong>;
        }
        if (word.startsWith('<') && word.endsWith('>')) {
          return <strong key={`${paragraphIndex}-${wordIndex}`} style={{color: '#74fa9c'}}>{word.slice(1, -1)}{spaceAfter}</strong>;
        }
        // Add space after current word, unless next word starts with punctuation
        return `${word}${spaceAfter}`;
      });
  
      return <div key={paragraphIndex}>{processedWords}</div>;
    });
  
    return processedParagraphs;
  };  

  return (
    <div className={`home-description-card ${theme === 'light' ? '': 'dark'}`}>
      <div className="home-description-card-title">Lineup Insights</div>
      <div className="home-description-card-body">
        {waiting ? (
          <div>
            <SyncLoader size={6} color={"rgba(54, 215, 183, 0.5"} />
          </div>
        ) : (
          renderTextWithBold(body)
        )}
        {/* {renderTextWithBold(body)} */}
      </div>
      {!isLoading && (
        <button className='generate-description-button' onClick={onRefresh}>
          Generate New Suggestions
        </button>
      )}
    </div>
  );
};

export default HomeDescriptionCard;
