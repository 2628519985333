import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Cookies from 'js-cookie';

function PublicRoute({ component: Component, ...rest }) {
  const isLoggedIn = Cookies.get('sessionId') === undefined;

  return isLoggedIn ? <Outlet /> : <Navigate to="/" />;
}

export default PublicRoute;