import React, { useState } from "react";
import LoginForm from "../components/LoginForm/LoginForm";
import Cookies from 'js-cookie';
import { useNavigate  } from 'react-router-dom';

function LoginPage() {
  const navigate = useNavigate ();
  const [errorMessage, setErrorMessage] = useState('');
  
  const handleLogin = (email, password) => {
    return new Promise(async (resolve, reject) => {
      try {
        console.log(`${process.env.REACT_APP_BACKEND_URL}/login_submit`)
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/login_submit`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, password }),
        });
  
        if (response.status === 403) {
          const data = await response.json();
          navigate('/verification');
          resolve();
        } else if (!response.ok) {
          const data = await response.json();
          throw new Error(data.error);
        } else {
          const data = await response.json();
          console.log('Data received:', data);
          Cookies.set('sessionId', data.sessionId, { expires: 30 });
          localStorage.setItem('userName', data.userName);
          localStorage.setItem('userInitials', data.userInitials);
          localStorage.setItem('refreshOnlyOnClick', true);
          localStorage.setItem('navbarState', false);
          navigate('/');
          resolve();
        }
      } catch (error) {
        console.error(error);
        setErrorMessage(error.message);
        Cookies.remove('sessionId');
        reject(error);
      }
    });
  };
  

  return (
    <div className="login-page"> <LoginForm onSubmit={handleLogin} errorMessage={errorMessage}/> </div>
  );
}

export default LoginPage;