import React, { useState, useEffect, useContext } from 'react';
import { ThemeContext } from '../../theme-context';
import FilterSection from "../Filters/FilterSection";
import HomeDescriptionCard from "../Cards/HomeDescriptionCard";
import HomeTableCard from "../Cards/HomeTableCard";
import BarChart from "../Charts/BarChart";
import ScatterChart from "../Charts/ScatterChart";
import BubbleChart from "../Charts/BubbleChart";
import LineChart from "../Charts/LineChart";
import DoughnutChart from "../Charts/DoughnutChart";
import RadarChart from "../Charts/RadarChart";
import { RingLoader } from 'react-spinners';

const HomePageLayout = ({fetchDataFunctions, transformFunctions, chartConfigs, activePage, pageTitle, transformTableData, retrieveDescription}) => {

  // Variables
  // Base variables 
  const { theme } = useContext(ThemeContext);
  const [loading, setLoading] = useState(true);
  const userName = localStorage.getItem("userName");
  const [allData, setAllData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const refreshOnlyOnClickState = (localStorage.getItem('refreshOnlyOnClick') ?? true);

  // Navbar status
  const [navbarOpen, setNavbarOpen] = useState(() => {
    const storedNavbarState = localStorage.getItem('navbarState');
    return storedNavbarState ? JSON.parse(storedNavbarState) : true;
  });
  
  // Iteratively update each chart
  const updateChartData = (chartInstance, newChartData) => {
    chartInstance.data.labels = newChartData.labels;
    newChartData.datasets.forEach((dataset, i) => {
      if (chartInstance.data.datasets[i]) {
        chartInstance.data.datasets[i].label = dataset.label;
        chartInstance.data.datasets[i].data = dataset.data;
        chartInstance.data.datasets[i].backgroundColor = dataset.backgroundColor;
        chartInstance.data.datasets[i].borderColor = dataset.borderColor;
        chartInstance.data.datasets[i].borderWidth = dataset.borderWidth;
        chartInstance.data.datasets[i].pointRadius = dataset.pointRadius;
      } else {
        chartInstance.data.datasets.push(dataset);
      }
    });

    // Remove any excess datasets from chartInstance
    while(chartInstance.data.datasets.length > newChartData.datasets.length) {
      chartInstance.data.datasets.pop();
    }
    chartInstance.update();
  };

  // Filter variables for the data
  const [selectedSeason, setSelectedSeason] = useState(() => {
    const selectedSeasonState = localStorage.getItem('selectedSeasonState');
    return selectedSeasonState ? JSON.parse(selectedSeasonState) : "All";
  });
  const [selectedWeek, setSelectedWeek] = useState(() => {
    const selectedWeekState = localStorage.getItem('selectedWeekState');
    return selectedWeekState ? JSON.parse(selectedWeekState) : "All";
  });
  const [managerToggle, setManagerToggle] = useState(() => {
    const managerToggleState = localStorage.getItem('managerToggleState');
    return managerToggleState ? JSON.parse(managerToggleState) : false;
  }); 
  const [selectedPosition, setSelectedPosition] = useState("All");
  const [selectedUser, setSelectedUser] = useState(userName);


// Retrieving data
// Fetch api data
useEffect(() => {
    const fetchPromises = fetchDataFunctions.map(fetchData => fetchData());
    Promise.all(fetchPromises)
      .then(allData => {
        setAllData(allData);
        setLoading(false);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, []);

  // Generate key takeaways using ChatGPT - abstract to more
  // refresh description - abstract and add to home page
  const refreshDescription = (chartId) => {
    const chartToUpdate = chartConfigs.find(chart => chart.id === chartId);
    if (!chartToUpdate || !chartToUpdate.setDescriptionText) {
        console.error(`Chart with ID ${chartId} not found or it has no setDescriptionText function.`);
        return;
    }
    
    chartToUpdate.setIsLoading(true); // Start loading when button is clicked
    chartToUpdate.setWaiting(true);

    // Connect to your Flask streaming endpoint
    const eventSource = new EventSource(`${process.env.REACT_APP_BACKEND_URL}/update_description?chartId=${chartId}&userName=${userName}&filters=${JSON.stringify({
      season: selectedSeason, 
      week: selectedWeek, 
      position: selectedPosition, 
      manager: managerToggle, 
      lineChartName: selectedUser, 
      page: activePage
    })}`);

    eventSource.onmessage = (event) => {
      const newDescriptionChunk = event.data;

      // Assuming setDescriptionText appends the new chunk to the existing text
      chartToUpdate.setDescriptionText(newDescriptionChunk);
      chartToUpdate.setWaiting(false);
    };

    eventSource.onopen = (event) => {
      // This can be used if you want to do something when the connection is established
    };

    eventSource.onerror = (error) => {
      console.error("EventSource failed:", error);
      chartToUpdate.setIsLoading(false);
      eventSource.close();
    };

    // Optionally, if your server sends an explicit end signal, you can handle it:
    eventSource.addEventListener('end', () => {
      chartToUpdate.setIsLoading(false);
      eventSource.close();
    });

    return () => {
      chartToUpdate.setIsLoading(false);
      eventSource.close();
    };

  };

  
  
  // Filter and transform data
  // Function to filter data based on season, week, and position
  const filterData = (data, selectedSeason, selectedWeek, selectedPosition, managerToggle) => {
    let filteredData = data;
  
    if (selectedSeason !== "All") {
      filteredData = data.filter((item) => item.season === selectedSeason);
    }
    if (selectedWeek !== "All") {
      filteredData = filteredData.filter((item) => item.week.toString() === selectedWeek.toString());
    }
    if (selectedPosition !== "All" && managerToggle) {
      filteredData = filteredData.filter((item) => item.pos === selectedPosition);
    }
  
    return filteredData;
  };
  
  // Iterate through data and charts to filter the data and transform for specific charts
  useEffect(() => {
    if (allData.length > 0) {
      allData.forEach((data, index) => {
        const filteredData = filterData(data, selectedSeason, selectedWeek, selectedPosition, managerToggle);
        const chartData = transformFunctions[index](filteredData, managerToggle, selectedUser, userName);
        chartConfigs[index].setData(chartData);
        if ((index === 0) && (refreshOnlyOnClickState === 'false')) {
          chartConfigs[index].setDescriptionText(retrieveDescription(index+1, selectedSeason, selectedWeek, selectedPosition, managerToggle, selectedUser, activePage, userName));
        }
      });
      if (selectedSeason === 'All'){
        let seasons = allData[0].map(data => data.season);
        let maxSeason = Math.max(...seasons);
        setSelectedSeason(maxSeason);
    
        // Filter data by the max season
        let dataForMaxSeason = allData[0].filter(data => data.season === maxSeason);
        if (selectedWeek === 'All' && dataForMaxSeason.length > 0) {
            let weeks = dataForMaxSeason.map(data => data.week);
            let maxWeek = Math.max(...weeks);
            setSelectedWeek(maxWeek);
        }
    } else if (selectedWeek === 'All'){
        // Assuming that if the season isn't 'All', you'd still want the max week for the selected season
        let dataForSelectedSeason = allData[0].filter(data => data.season === selectedSeason);
        if (dataForSelectedSeason.length > 0) {
            let weeks = dataForSelectedSeason.map(data => data.week);
            let maxWeek = Math.max(...weeks);
            setSelectedWeek(maxWeek);
        }
    }
    
      const filteredData = filterData(allData[0], selectedSeason, selectedWeek, selectedPosition, managerToggle);      

    //   Set table data
      let filteredDataByUser;
      if (managerToggle === false) {
         filteredDataByUser = filteredData.filter(item => item.team === userName && ((item.efficiency_type === 'roster_projected') || (item.efficiency_type === 'all_projected')));
      } else {
            filteredDataByUser = filteredData.filter(item => item.team === userName && ((item.efficiency_type === 'roster_actual') || (item.efficiency_type === 'all_actual')));
      }
      setTableData(transformTableData(filteredDataByUser))

    }
  }, [theme, selectedPosition, selectedSeason, selectedWeek, managerToggle, allData]);
  
  // Loading spinner
  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <RingLoader size={32} color={"#36d7b7"} />
      </div>
    );
  }

  return (
    <div className={`page-container ${navbarOpen ? 'open-navbar' : ''}`} id="page-content">
      <FilterSection selectedSeason={selectedSeason} setSelectedSeason={setSelectedSeason} 
                     selectedWeek={selectedWeek} setSelectedWeek={setSelectedWeek} 
                     managerToggle={managerToggle} setManagerToggle={setManagerToggle} 
                     activePage={activePage} pageTitle={pageTitle}
    />
      <div className="page-body-container">
        <div className="page-body-content">

            {/* Card containers */}
            <div className="home-chart-description-container">

                {/* Description (key takeaways) */}
                <HomeDescriptionCard isLoading={chartConfigs[0].isLoading} waiting={chartConfigs[0].waiting} body={chartConfigs[0].descriptionText} onRefresh={() => refreshDescription(chartConfigs[0].id)} />

                <div className="stacked-charts-container">

                {chartConfigs.map((chart, index) => (
                    <div key={index}>

                      {/* Charts */}
                        <div id={chart.id} className={`home-chart-card ${theme === 'light' ? '': 'dark'}`}>
                            <div className="home-card-title">
                              {`${selectedSeason === 'All' ? '2019-2022' : selectedSeason} ${selectedWeek === 'All' ? '' : 'Week ' + selectedWeek} ${chart.title}`}
                            </div>
                            
                            <div className="home-chart-card-body">
                              {chart.type === 'bar' ? (
                                <BarChart theme={theme} chartData={chart.data} chartRef={chart.chartRef} updateChartData={updateChartData} labelsPerPage={25} 
                                selectedPosition={selectedPosition} setSelectedPosition={setSelectedPosition} setSelectedUser={setSelectedUser}/>
                              ) : chart.type === 'scatter' ? (
                                <ScatterChart theme={theme} chartData={chart.data} chartRef={chart.chartRef} updateChartData={updateChartData} labelsPerPage={25} 
                                selectedPosition={selectedPosition} setSelectedPosition={setSelectedPosition} setSelectedUser={setSelectedUser}
                                xTitle={chart.xTitle} yTitle={chart.yTitle} />
                              ) : chart.type === 'line' ? (
                                <LineChart theme={theme} chartData={chart.data} chartRef={chart.chartRef} updateChartData={updateChartData} labelsPerPage={25} 
                                selectedPosition={selectedPosition} setSelectedPosition={setSelectedPosition} setSelectedUser={setSelectedUser} 
                                xTitle={chart.xTitle} yTitle={chart.yTitle} />
                              ) : chart.type === 'doughnut' ? (
                                <DoughnutChart theme={theme} chartData={chart.data} chartRef={chart.chartRef} updateChartData={updateChartData} labelsPerPage={25} 
                                selectedPosition={selectedPosition} setSelectedPosition={setSelectedPosition} setSelectedUser={setSelectedUser} 
                                xTitle={chart.xTitle} yTitle={chart.yTitle} />
                              ) : chart.type === 'radar' ? (
                                <RadarChart theme={theme} chartData={chart.data} chartRef={chart.chartRef} updateChartData={updateChartData} labelsPerPage={25} 
                                selectedPosition={selectedPosition} setSelectedPosition={setSelectedPosition} setSelectedUser={setSelectedUser} 
                                xTitle={chart.xTitle} yTitle={chart.yTitle} />
                              ) : (
                                <BubbleChart theme={theme} chartData={chart.data} chartRef={chart.chartRef} updateChartData={updateChartData} labelsPerPage={500} 
                                selectedPosition={selectedPosition} setSelectedPosition={setSelectedPosition} setSelectedUser={setSelectedUser} 
                                xTitle={chart.xTitle} yTitle={chart.yTitle} />
                              )}
                            </div> 
                        </div>
                    </div>
                ))}
                    
                </div>
            </div>
            <HomeTableCard title={'Suggested Lineup Changes'} data={tableData} />
                    
            </div>
        </div>
    </div>
  );
};

export default HomePageLayout;
