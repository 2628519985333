import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import { useNavigate, useLocation } from 'react-router-dom';

function VerificationRedirectPage() {

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const sessionId = searchParams.get('session_id');
    const userName = searchParams.get('userName');
    const userInitials = searchParams.get('userInitials');
    Cookies.set('sessionId', sessionId, { expires: 30 });
    localStorage.setItem('userName', userName);
    localStorage.setItem('userInitials', userInitials);
    localStorage.setItem('refreshOnlyOnClick', true);
    localStorage.setItem('navbarState', false);
    navigate('/welcome');
  }, [location.search]);


  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <form>
        <h1>Verification Required</h1>
        <br />
        <p>Please check your email for verification link</p>
      </form>
    </div>
  );
}

export default VerificationRedirectPage;
