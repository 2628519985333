import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../LoginForm/LoginForm.css";
import { RingLoader } from 'react-spinners';

function SignupForm({ onSubmit, errorMessage }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      await onSubmit(email, password);
      console.log('Submitting Data:', {email, password})
    } 
    finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <form onSubmit={handleSubmit}>
        <div id="content">
            <div id="react">
                <div id="inner-ellipse-left"></div>
                <div id="inner-ellipse-top"></div>
                <div id="dot">
                  <div className="stripe stripe1"></div>
                  <div className="stripe"></div>
                  <div className="stripe stripe2"></div>  
                </div> 
                <div id="inner-ellipse-right"></div>
                <div class="Atom">
                    <div class="Atom-orbit Atom-orbit--left Atom-orbit--foreground">
                        <div class="Atom-electron"></div>
                    </div>
                    <div class="Atom-orbit Atom-orbit--right Atom-orbit--foreground">
                        <div class="Atom-electron"></div>
                    </div>
                    <div class="Atom-orbit Atom-orbit--top Atom-orbit--foreground"> 
                        <div class="Atom-electron"></div>
                    </div>
                </div>
            </div>
        </div>
        <h1>Sign Up</h1>
          <p className="email-message">Please sign up using your league email</p>
          <input type="text" name="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
          <input type="password" name="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
        <br />
        <button type="submit" className="login-signup-button" disabled={isLoading}>
          {isLoading ? <RingLoader size={24} color={"#36d7b7"} /> : 'Sign Up'}
        </button>
        <div className="email-message"> Already have an account? <Link to="/login">Login</Link></div>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </form>
    </div>
  );  
}

export default SignupForm;