import React from 'react';

function VerificationPage() {

  return (
    <div className="login-page" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <form>
        <h1>Verification Required</h1>
        <br />
        <p>Please check your email for verification link</p>
      </form>
    </div>
  );
}

export default VerificationPage;
