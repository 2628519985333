// DoughnutChart.js
import BaseChart from './BaseChart';

const DoughnutChart = (props) => {
  const config = {
    dataLabels: {
        formatter: function(value, data) {
            const label = data.dataset.data;
            if (label[0] > 0 && value === label[0]) {
                return `${value}%`;
            }
            else if (label[2] > 0 && value === label[2]) {
                return `${value}%`;
            }
            else { 
                return null; // Do not show labels for the transparent part of the doughnut
            }
        },
    },
      legend: {
        display: true
    },

    // ##### NEED TO FIX #####
        toolTips: {
          callbacks: {
            label: function(tooltipItem, data) {
              const dataset = data.datasets[tooltipItem.datasetIndex];
              const value = dataset.data[tooltipItem.index];
              if (tooltipItem.index === 0) {
                return `${['roster_actual', '', 'all_actual', ''][tooltipItem.datasetIndex]}: ${value.toFixed(2)}%`;
              }
              return null; // Do not show tooltips for the transparent part of the doughnut
            },
          },
        },

    scales: {
        x: {
            display: false,
        },
        y: {
            display: false,
        },
    },
  };

  return <BaseChart {...props} chartType="doughnut" config={config} />;
};

export default DoughnutChart;
