import BaseChart from './BaseChart';

const ScatterChart = ({xTitle, yTitle, ...props}) => {
    const config = {
        dataLabels: {
            formatter: function(value, context) {
                return context.chart.data.labels[context.dataIndex];
            },
            align: 'bottom',
            anchor: 'center',
            offset: 6,
            font: {
                size: 10
            }
        },
        xTitle: {
            display: true,
            text: xTitle || 'Projected Points'
        },
        yTitle: {
            display: true,
            text: yTitle || 'Actual Points'
        },
        legend: {
            display: false
        }
    };

  return <BaseChart {...props} chartType="scatter" config={config} />;
};

export default ScatterChart;
