import BaseChart from './BaseChart';

const BubbleChart = ({xTitle, yTitle, xLabel, yLabel, ...props}) => {
    const config = {
        dataLabels: {
            formatter: function(value, context) {
                return context.chart.data.labels[context.dataIndex];
            },
            align: 'bottom',
            anchor: 'center',
            offset: 6,
            font: {
                size: 0
            }
        },

        xTicks: {
            unitStepSize: 1,
            maxRotation: 45,
            minRotation: 45,
            fontSize: 0,
            callback: function (value) {
                return ['Austin', 'Chris', 'Garrett', 'Jack', 'Jacob', 'Kevin G', 'Kevin K', 'Michael', 'Nick', 'Paul', 'Sam', 'Will'][value]; // map numerical index back to manager name
            },
        },
        yTicks: {
            callback: function (value) {
                return ['Will', 'Sam', 'Paul', 'Nick', 'Michael', 'Kevin K', 'Kevin G', 'Jacob', 'Jack', 'Garrett', 'Chris', 'Austin'][value]; // map numerical index back to manager name
            },
        },
        
        xTitle: {
            display: true,
            text: xTitle || 'Projected Points'
        },
        yTitle: {
            display: true,
            text: yTitle || 'Actual Points'
        },
        legend: {
            display: false
        }
    };

  return <BaseChart {...props} chartType="bubble" config={config} />;
};

export default BubbleChart;
