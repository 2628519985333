import React, { useState, useEffect, useContext } from 'react';
import { ThemeContext } from '../../theme-context';
import FilterSection from "../Filters/FilterSection";
import DescriptionCard from "../Cards/DescriptionCard";
import BarChart from "../Charts/BarChart";
import ScatterChart from "../Charts/ScatterChart";
import BubbleChart from "../Charts/BubbleChart";
import LineChart from "../Charts/LineChart";
import { RingLoader } from 'react-spinners';

const ChartPageLayout = ({fetchDataFunctions, transformFunctions, chartConfigs, activePage, pageTitle, retrieveDescription}) => {

  // Variables
  // Base variables 
  const { theme } = useContext(ThemeContext);
  const [loading, setLoading] = useState(true);
  const userName = localStorage.getItem("userName");
  const [activeChart, setActiveChart] = useState(1);
  const [allData, setAllData] = useState([]);
  const refreshOnlyOnClickState = (localStorage.getItem('refreshOnlyOnClick') ?? false);

  // Navbar status
  const [navbarOpen, setNavbarOpen] = useState(() => {
    const storedNavbarState = localStorage.getItem('navbarState');
    return storedNavbarState ? JSON.parse(storedNavbarState) : true;
  });
  
  // Iteratively update each chart
  const updateChartData = (chartInstance, newChartData) => {
    chartInstance.data.labels = newChartData.labels;
    newChartData.datasets.forEach((dataset, i) => {
      if (chartInstance.data.datasets[i]) {
        chartInstance.data.datasets[i].label = dataset.label;
        chartInstance.data.datasets[i].data = dataset.data;
        chartInstance.data.datasets[i].backgroundColor = dataset.backgroundColor;
        chartInstance.data.datasets[i].borderColor = dataset.borderColor;
        chartInstance.data.datasets[i].borderWidth = dataset.borderWidth;
        chartInstance.data.datasets[i].pointRadius = dataset.pointRadius;
      } else {
        chartInstance.data.datasets.push(dataset);
      }
    });

    // Remove any excess datasets from chartInstance
    while(chartInstance.data.datasets.length > newChartData.datasets.length) {
      chartInstance.data.datasets.pop();
    }
    chartInstance.update();
  };

  // Filter variables for the data
  const [selectedSeason, setSelectedSeason] = useState(() => {
    const selectedSeasonState = localStorage.getItem('selectedSeasonState');
    return selectedSeasonState ? JSON.parse(selectedSeasonState) : "All";
  });
  const [selectedWeek, setSelectedWeek] = useState(() => {
    const selectedWeekState = localStorage.getItem('selectedWeekState');
    return selectedWeekState ? JSON.parse(selectedWeekState) : "All";
  });
  const [managerToggle, setManagerToggle] = useState(() => {
    const managerToggleState = localStorage.getItem('managerToggleState');
    return managerToggleState ? JSON.parse(managerToggleState) : false;
  }); 
  const [selectedPosition, setSelectedPosition] = useState("All");
  const [selectedUser, setSelectedUser] = useState(userName);


  // Handling auto-scrolling to charts
  // Scroll to chart
  const handleScrollToChart = (chartNumber) => {
    const chart = document.getElementById(`${chartNumber}`);
    const rect = chart.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    const scrollPosition = rect.top + window.pageYOffset - windowHeight / 3;
    window.scrollTo({ top: scrollPosition, behavior: "smooth" });
  };

  // Scroll Effect
  useEffect(() => {
    if (typeof window !== "undefined") {
      // Add scroll event listener
      const handleScroll = () => {
        const chartContainerElements = document.querySelectorAll(".chart-description-container");
        const windowHeight = window.innerHeight;
        const middleOfViewport = windowHeight / 2;
        let closestChartNumber = null;
        let closestDistance = Infinity;
  
        chartContainerElements.forEach((element, index) => {
          const rect = element.getBoundingClientRect();
          const distanceToMiddle = Math.abs(rect.top + rect.height / 2 - middleOfViewport);
  
          if (distanceToMiddle < closestDistance) {
            closestChartNumber = index + 1;
            closestDistance = distanceToMiddle;
          }
        });
  
        setActiveChart(closestChartNumber);
      };
  
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

// Retrieving data
// Fetch api data
useEffect(() => {
    const fetchPromises = fetchDataFunctions.map(fetchData => fetchData());
    Promise.all(fetchPromises)
      .then(allData => {
        setAllData(allData);
        setLoading(false);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, []);

  // Generate key takeaways using ChatGPT - abstract to more
  // refresh description - abstract and add to home page
  const refreshDescription = (chartId) => {
    const chartToUpdate = chartConfigs.find(chart => chart.id === chartId);
    if (!chartToUpdate || !chartToUpdate.setDescriptionText) {
        console.error(`Chart with ID ${chartId} not found or it has no setDescriptionText function.`);
        return;
    }
    
    chartToUpdate.setIsLoading(true); // Start loading when button is clicked
    chartToUpdate.setWaiting(true);

    // Connect to your Flask streaming endpoint
    const eventSource = new EventSource(`${process.env.REACT_APP_BACKEND_URL}/update_description?chartId=${chartId}&userName=${userName}&filters=${JSON.stringify({
      season: selectedSeason, 
      week: selectedWeek, 
      position: selectedPosition, 
      manager: managerToggle, 
      lineChartName: selectedUser, 
      page: activePage
    })}`);

    eventSource.onmessage = (event) => {
      const newDescriptionChunk = event.data;

      // Assuming setDescriptionText appends the new chunk to the existing text
      chartToUpdate.setDescriptionText(newDescriptionChunk);
      chartToUpdate.setWaiting(false);
    };

    eventSource.onopen = (event) => {
      // This can be used if you want to do something when the connection is established
    };

    eventSource.onerror = (error) => {
      console.error("EventSource failed:", error);
      chartToUpdate.setIsLoading(false);
      eventSource.close();
    };

    // Optionally, if your server sends an explicit end signal, you can handle it:
    eventSource.addEventListener('end', () => {
      chartToUpdate.setIsLoading(false);
      eventSource.close();
    });
  };
  
  
  // Filter and transform data
  // Function to filter data based on season, week, and position
  const filterData = (data, selectedSeason, selectedWeek, selectedPosition, managerToggle) => {
    let filteredData = data;
  
    if (selectedSeason !== "All") {
      filteredData = data.filter((item) => item.season === selectedSeason);
    }
    if (selectedWeek !== "All") {
      filteredData = filteredData.filter((item) => item.week.toString() === selectedWeek.toString());
    }
    if (selectedPosition !== "All" && managerToggle) {
      filteredData = filteredData.filter((item) => item.pos === selectedPosition);
    }
  
    return filteredData;
  };
  
  // Transform data
  useEffect(() => {
    if (allData.length > 0) {
      allData.forEach((data, index) => {
        const filteredData = filterData(data, selectedSeason, selectedWeek, selectedPosition, managerToggle);
        // Use the transform function corresponding to this dataset
        const chartData = transformFunctions[index](filteredData, managerToggle, selectedUser, userName);
        chartConfigs[index].setData(chartData);
        if ((refreshOnlyOnClickState === 'false')) {
          chartConfigs[index].setDescriptionText(retrieveDescription(index+1, selectedSeason, selectedWeek, selectedPosition, managerToggle, selectedUser, activePage, userName));
        }
      });
    }
  }, [theme, selectedPosition, selectedSeason, selectedWeek, managerToggle, selectedUser, allData]);
  
  // Loading spinner
  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <RingLoader size={32} color={"#36d7b7"} />
      </div>
    );
  }

  return (
    <div className={`page-container ${navbarOpen ? 'open-navbar' : ''}`} id="page-content">
      <FilterSection selectedSeason={selectedSeason} setSelectedSeason={setSelectedSeason} 
                     selectedWeek={selectedWeek} setSelectedWeek={setSelectedWeek} 
                     managerToggle={managerToggle} setManagerToggle={setManagerToggle} 
                     activePage={activePage} pageTitle={pageTitle}
    />
      <div className="page-body-container">
        <div className="page-body-content">

          {/* Chart navigation */}
            <div className="navigation-container">
                <ul className="navigation-list">
                {chartConfigs.map((chart) => (
                    <li 
                        key={chart.id}
                        onClick={() => handleScrollToChart(chart.id)} 
                        className={`${activeChart === chart.id ? "active-chart" : ""} ${theme === 'light' ? '': 'dark'}`}
                    >
                      {chart.title}
                    </li>
                ))}
                </ul>
            </div>

            {/* Card containers */}
            <div className="card-container">
                {chartConfigs.map((chart, index) => (
                    <div key={index} className="chart-description-container">

                      {/* Charts */}
                        <div id={chart.id} className={`chart-card ${theme === 'light' ? '': 'dark'}`}>
                            <div className="card-title">
                              {`${selectedSeason === 'All' ? '2019-2022' : selectedSeason} ${selectedWeek === 'All' ? '' : 'Week ' + selectedWeek} ${chart.title}`}
                            </div>
                            
                            <div className="chart-card-body">
                              {chart.type === 'bar' ? (
                                <BarChart theme={theme} chartData={chart.data} chartRef={chart.chartRef} updateChartData={updateChartData} labelsPerPage={25} 
                                selectedPosition={selectedPosition} setSelectedPosition={setSelectedPosition} setSelectedUser={setSelectedUser}/>
                              ) : chart.type === 'scatter' ? (
                                <ScatterChart theme={theme} chartData={chart.data} chartRef={chart.chartRef} updateChartData={updateChartData} labelsPerPage={25} 
                                selectedPosition={selectedPosition} setSelectedPosition={setSelectedPosition} setSelectedUser={setSelectedUser}
                                xTitle={chart.xTitle} yTitle={chart.yTitle} />
                              ) : chart.type === 'line' ? (
                                <LineChart theme={theme} chartData={chart.data} chartRef={chart.chartRef} updateChartData={updateChartData} labelsPerPage={25} 
                                selectedPosition={selectedPosition} setSelectedPosition={setSelectedPosition} setSelectedUser={setSelectedUser} 
                                xTitle={chart.xTitle} yTitle={chart.yTitle} />
                              ) : (
                                <BubbleChart theme={theme} chartData={chart.data} chartRef={chart.chartRef} updateChartData={updateChartData} labelsPerPage={500} 
                                selectedPosition={selectedPosition} setSelectedPosition={setSelectedPosition} setSelectedUser={setSelectedUser} 
                                xTitle={chart.xTitle} yTitle={chart.yTitle} />
                              )}
                            </div>
                            
                        </div>

                        {/* Description (key takeaways) */}
                        <DescriptionCard isLoading={chart.isLoading} waiting={chart.waiting} body={chart.descriptionText} onRefresh={() => refreshDescription(chart.id)} />
                    </div>
                ))}
            </div>
        </div>
      </div>
    </div>
  );
};

export default ChartPageLayout;
