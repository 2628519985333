// LineChart.js
import BaseChart from './BaseChart';

const LineChart = ({xTitle, yTitle, ...props}) => {
  const config = {
    dataLabels: {
        display: false,
    },
    xTitle: {
        display: true,
        text: xTitle || 'Week'
    },
    yTitle: {
        display: true,
        text: yTitle || 'Actual vs Projected'
    },
    legend: {
        display: true
    }
  };

  return <BaseChart {...props} chartType="line" config={config} />;
};

export default LineChart;
