import React, { useState, useRef } from "react";
import ChartPageLayout from "../components/Layout/ChartPageLayout";

const ActualvsProjected = () => {
  const refreshOnlyOnClickState = (localStorage.getItem('refreshOnlyOnClick') ?? true);
  
  // Position variables
  const positionColors = {
  "QB": "#fa8174",
  "RB": "#fac274",
  "WR": "#fae474",
  "TE": "#74fa9c",
  "K": "#81b1d2",
  "D/ST": "#e474fa"
  };

  // Chart variables
  const [chartData1, setChartData1] = useState({labels: [], datasets: [{label: [], data: [], backgroundColor: []},]});
  const [chartData2, setChartData2] = useState({labels: [], datasets: [{label: ['Projected, Actual, Delta'], data: [], backgroundColor: []},]});
  const [chartData3, setChartData3] = useState({labels: [], datasets: [{label: [], data: [], backgroundColor: []},]});
  const chartRef1 = useRef(null);
  const chartRef2 = useRef(null);
  const chartRef3 = useRef(null);
  const [descriptionText1, setDescriptionText1] = useState('')
  const [descriptionText2, setDescriptionText2] = useState('')
  const [descriptionText3, setDescriptionText3] = useState('')
  const [isLoading1, setIsLoading1] = useState(false)
  const [isLoading2, setIsLoading2] = useState(false)
  const [isLoading3, setIsLoading3] = useState(false)
  const [waiting1, setWaiting1] = useState(false)
  const [waiting2, setWaiting2] = useState(false)
  const [waiting3, setWaiting3] = useState(false)

  // Configure charts
  const chartConfigs = [
    { type: 'bar', data: chartData1, setData: setChartData1, chartRef: chartRef1, id:1, title: 'Actual - Projected Delta', 
    descriptionText: descriptionText1, setDescriptionText: setDescriptionText1, isLoading: isLoading1, setIsLoading: setIsLoading1, waiting: waiting1, setWaiting: setWaiting1 },
    { type: 'scatter', data: chartData2, setData: setChartData2, chartRef: chartRef2, id:2, title: 'Actual vs Projected Points', 
    descriptionText: descriptionText2, setDescriptionText: setDescriptionText2, isLoading: isLoading2, setIsLoading: setIsLoading2, waiting: waiting2, setWaiting: setWaiting2,
    xTitle: 'Projected Points', yTitle: 'Actual Points' },
    { type: 'line', data: chartData3, setData: setChartData3, chartRef: chartRef3, id:3, title: 'Weekly Points Scored', 
    descriptionText: descriptionText3, setDescriptionText: setDescriptionText3, isLoading: isLoading3, setIsLoading: setIsLoading3, waiting: waiting3, setWaiting: setWaiting3,
    xTitle: 'Week', yTitle: 'Actual vs Projected'},
  ];
 
  // Fetch data - 1 api call per chart
  const fetchDataFunctions = [
    () => fetch(`${process.env.REACT_APP_BACKEND_URL}/actual_vs_projected`).then(response => response.json()),
    () => fetch(`${process.env.REACT_APP_BACKEND_URL}/actual_vs_projected`).then(response => response.json()),
    () => fetch(`${process.env.REACT_APP_BACKEND_URL}/actual_vs_projected`).then(response => response.json())
  ];

  // Declare individual functions for each chart
  // Actual - Projected Bar Chart
  const getDataChart1 = (filteredData, managerToggle, userName) => {
    const groupedData = {};
    const playerPositions = {};
  
    filteredData.forEach((item) => {
      const { player, pos, score_diff, team } = item;
      const groupKey = managerToggle ? player : team;
      if (!groupedData[groupKey]) {
        groupedData[groupKey] = 0;
        if (managerToggle) playerPositions[player] = pos;
      }
      groupedData[groupKey] += score_diff;
    });
  
    const sortedData = Object.entries(groupedData).sort((a, b) => b[1] - a[1]);
    const backgroundColors = sortedData.map(([key, value]) => {
      let color = value >= 0 ? "#81b1d2" : "#fa8174";
      color = key === userName ? "#74fa9c" : color;
      return managerToggle ? positionColors[playerPositions[key]] : color;
    });
  
    return {
      labels: sortedData.map(([key, _]) => key),
      datasets: [{
        label: 'Actual minus Projected Points Scored',
        data: sortedData.map(([_, value]) => value),
        backgroundColor: backgroundColors,
        borderWidth: 1,
      }],
    };
  }
  
  // Actual vs Projected Scatter Plot
  const getDataChart2 = (filteredData, managerToggle, userName) => {
  const groupedData = {};
  const playerPositions = {};

  filteredData.forEach((item) => {
    const { player, pos, projected, actual, team } = item;
    const groupKey = managerToggle ? player : team;
    if (!groupedData[groupKey]) {
      groupedData[groupKey] = {'x': 0, 'y': 0, 'r': 10};
      if (managerToggle) playerPositions[player] = pos;
    }
    groupedData[groupKey]['x'] += projected;
    groupedData[groupKey]['y'] += actual;
  });

  const sortedData = Object.entries(groupedData).sort((a, b) => b[1]['y'] - a[1]['y']);
  const backgroundColors = sortedData.map(([key, value]) => {
    let color = value['x'] <= value['y'] ? "#81b1d2" : "#fa8174";
    color = key === userName ? "#74fa9c" : color;
    return managerToggle ? positionColors[playerPositions[key]] : color;
  });

  return {
    labels: sortedData.map(([key, _]) => key),
    datasets: [{
      label: 'Projected, Actual',
      data: sortedData.map(([_, value]) => value),
      radius: 10,
      pointRadius: 10,
      pointHoverRadius: 20,
      backgroundColor: backgroundColors,
      borderWidth: 1,
    }],
  };
}

// Weekly Points Scored
const getDataChart3 = (filteredData, managerToggle, selectedUser, userName) => {
  const groupedData = {};
  let highestScoreDiff = -Infinity;
  let firstPlayer = null;

  filteredData.forEach((item) => {
    const { week, player, actual, projected, team } = item;
    const groupKey = managerToggle ? player : team;
    if (!groupedData[groupKey]) {
      groupedData[groupKey] = {'Projected': Array.from({length: 18}, () => 0), 'Actual': Array.from({length: 18}, () => 0)};
    }
    groupedData[groupKey]['Projected'][week - 1] += projected;
    groupedData[groupKey]['Actual'][week - 1] += actual;

    if (managerToggle) {
      const aggregateScoreDiff = groupedData[player]['Actual'].reduce((a, b) => a + b, 0);
      if (aggregateScoreDiff > highestScoreDiff) {
        highestScoreDiff = aggregateScoreDiff;
        firstPlayer = player;
      }
    }
  });

  let selectedUserData = groupedData[selectedUser];
  if (selectedUserData === undefined && managerToggle) {
    selectedUser = firstPlayer;
    selectedUserData = groupedData[selectedUser];
  } else if (selectedUserData === undefined && !managerToggle) {
    selectedUser = userName;
    selectedUserData = groupedData[selectedUser];
  }

  return {
    labels: Array.from({length: 18}, (_, i) => `${i + 1}`),
    datasets: [
      {
        label: selectedUser + ' Proj.',
        data: selectedUserData['Projected'],
        borderColor: '#fa8174',
        backgroundColor: '#fa8174',
        borderWidth: 2,
        pointRadius: 2,
      },
      {
        label: selectedUser + ' Actual',
        data: selectedUserData['Actual'],
        borderColor: '#81b1d2',
        backgroundColor: '#81b1d2',
        borderWidth: 2,
        pointRadius: 2,
      },
    ]
  };
}


// Put transformations in an array, corresponding to each api call and chart
const transformFunctions = [
    (filteredData, managerToggle, selectedUser, userName) => {
        return getDataChart1(filteredData, managerToggle, userName)
    },
    (filteredData, managerToggle, selectedUser, userName) => {
        return getDataChart2(filteredData, managerToggle, userName)
    },
    (filteredData, managerToggle, selectedUser, userName) => {
        return getDataChart3(filteredData, managerToggle, selectedUser, userName)
    }
  ];

  // Grab the latest description from the database or create a new description 
  const retrieveDescription = async (chartId, selectedSeason, selectedWeek, selectedPosition, managerToggle, selectedUser, activePage, userName) => {
    const chartToUpdate = chartConfigs.find(chart => chart.id === chartId);
    chartToUpdate.setIsLoading(true); // Start loading when button is clicked
    chartToUpdate.setWaiting(true);
    
    let response;
    try {
      if (refreshOnlyOnClickState === 'false') {
        const filters = { season: selectedSeason, week: selectedWeek, position: selectedPosition, manager: managerToggle, lineChartName: selectedUser, page: activePage};
        response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/retrieve_description?chartId=${chartId}&userName=${userName}&filters=${JSON.stringify(filters)}`);
      }

      // Checking if the content type indicates a stream
      if ((response.headers.get('Content-Type').includes('text/event-stream')) && (refreshOnlyOnClickState === 'false')) {
          // Handle streaming here
          const eventSource = new EventSource(response.url);

          let accumulatedData = '';
          eventSource.onmessage = (event) => {
              chartToUpdate.setDescriptionText(event.data);
              chartToUpdate.setWaiting(false);
          };

          eventSource.onerror = (error) => {
              console.error("Error in EventSource:", error);
              eventSource.close();
          };

          eventSource.onopen = (event) => {
              chartToUpdate.setIsLoading(false);
          };

          return;
      }

      // If not streaming, then handle as regular JSON response
      if (refreshOnlyOnClickState === 'false') {
        const newDescription = await response.json();
        if (chartToUpdate && chartToUpdate.setDescriptionText) {
          chartToUpdate.setDescriptionText(newDescription);
        } else {
          console.error(`Chart with ID ${chartId} not found or it has no setDescriptionText function.`);
        }
      }

      } catch (error) {
        console.error("Error refreshing description:", error);
      } finally {
        if (!response.headers.get('Content-Type').includes('text/event-stream')) {
          chartToUpdate.setWaiting(false);
          chartToUpdate.setIsLoading(false); // Stop loading if it's not a stream
        }
      }
      chartToUpdate.setWaiting(false);
      chartToUpdate.setIsLoading(false);
    };

  return (
    <div className="actualvsprojected">
        <ChartPageLayout fetchDataFunctions={fetchDataFunctions} transformFunctions={transformFunctions} chartConfigs={chartConfigs} 
                         activePage={'actual_vs_projected'} pageTitle={'Actual vs Projected Points Scored'} retrieveDescription={retrieveDescription}
        >
        </ChartPageLayout>
    </div>
    );
};

export default ActualvsProjected;

