// BarChart.js
import BaseChart from './BaseChart';

const BarChart = (props) => {
  const config = {
    dataLabels: {
      formatter: function(value, context) {
        return value.toFixed(0); // Change this to control how the value is displayed
      },
      anchor: function(context) {
        var value = context.dataset.data[context.dataIndex];
        return value < 0 ? 'start' : 'end';
      },
      align: function(context) {
        var value = context.dataset.data[context.dataIndex];
        return value < 0 ? 'start' : 'end';
      },
      offset: 2,
    },
    legend: {
      display: false
  }
  };

  return <BaseChart {...props} chartType="bar" config={config} />;
};

export default BarChart;
