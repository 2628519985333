import React, { useState, useContext } from 'react';
import { ThemeContext } from '../../theme-context';
import { AiOutlineBarChart, AiOutlineHome } from 'react-icons/ai';
import {BsMoon, BsSun} from 'react-icons/bs'
import {FiHelpCircle} from 'react-icons/fi'
import Modal from 'react-modal';
import './NavBar.css';

function Navbar(props) {
  const { theme, toggleTheme } = useContext(ThemeContext);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);
  };
  const modalContent = ["This fantasy football webapp was my (Sam DeTrempe's) attempt at becoming more proficient with front-end development and large language models.", <br />, <br />, 
                        "The goal of the project was to display some interesting and fun graphs of our league's data whilst leveraging chatGPT to provide some textual insights.\
                        (The takeaway text is generated in real time, which is why it can take several seconds to update)", <br />, <br />, 
                        "While some of these graphs will prove informative, there are no doubt superior visuals that can yield greater insights.", <br />, <br />,
                        "If you have any ideas for additional or different graphs or pieces of functionality, let me know!"]
  
  return (
    <div className={`navbar ${props.navbarOpen ? "open" : ""} ${theme === 'light' ? '': 'dark'}`}>
      <div className="navbar-content">
        {/* Top part */}
        <a href="/" className="navbar-link" title="Home">
          <AiOutlineHome className={`navbar-icon ${theme === 'light' ? '': 'dark'}`} />
          <span className={`navbar-text ${theme === 'light' ? '': 'dark'}`}>{props.navbarOpen ? "Home" : ""}</span>
        </a>

        <a href="/actual_vs_projected" className="navbar-link" title="League Analytics">
          <AiOutlineBarChart className={`navbar-icon ${theme === 'light' ? '': 'dark'}`} />
          <span className={`navbar-text ${theme === 'light' ? '': 'dark'}`}>{props.navbarOpen ? "League Analytics" : ""}</span>
        </a>

        {/* Bottom part */}
        <hr className="navbar-separator" />

        <button className="navbar-link-button" title={theme === 'light' ? "Dark Mode" : "Light Mode"} onClick={toggleTheme}>
          {theme === 'light' ? (
            <BsMoon className={`navbar-icon ${theme === 'light' ? '': 'dark'}`} />
          ) : (
            <BsSun className={`navbar-icon ${theme === 'light' ? '': 'dark'}`} />
          )}
          <span className={`navbar-text ${theme === 'light' ? '': 'dark'}`}>{props.navbarOpen ? (theme === 'light' ? "Dark Mode" : "Light Mode") : ""}</span>
        </button>

        <button className="navbar-link-button navbar-link-help" title="Help" onClick={toggleModal}>
          <FiHelpCircle className={`navbar-icon ${theme === 'light' ? '': 'dark'}`} />
          <span className={`navbar-text ${theme === 'light' ? '': 'dark'}`}>{props.navbarOpen ? "Help" : ""}</span>
        </button>

        <Modal
        isOpen={modalIsOpen}
        onRequestClose={toggleModal}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // this will give you a semi-transparent background
            backdropFilter: 'blur(5px)', // this will blur the background
          },
          content: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)', // this will center the modal
            height: '450px',
            width: '400px',
            background: theme === 'dark' ? 'rgb(52,53,65)' : '#fff',
            color: theme === 'dark' ? 'rgba(197,197,210)' : 'initial',
            borderColor: 'transparent',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '20px',
            outline: 'none',
            padding: '20px',
          }
        }}
      >
        <h2 className="modal-title">About this App</h2>
        <p className="modal-text">{modalContent}</p>
      </Modal>

      </div>
    </div>
  );
}

export default Navbar;
