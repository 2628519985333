// BaseChart.js
import React, { useEffect, useState, useRef, useMemo } from 'react';
import ChartJS from 'chart.js/auto';
import './Charts.css'
import ChartDataLabels from 'chartjs-plugin-datalabels';
import annotationPlugin from 'chartjs-plugin-annotation';
ChartJS.register(ChartDataLabels);
ChartJS.register(annotationPlugin);

// Limit the number of labels to a reasonable number
const getDisplayedData = (data, page, labelsPerPage, chartType) => {
    if (data.labels.length < page * labelsPerPage) {
      page = 0;
    }
    const startIndex = page * labelsPerPage;
    const endIndex = startIndex + labelsPerPage;
    const displayedLabels = data.labels.slice(startIndex, endIndex);
    const displayedDatasets = data.datasets.map((dataset) => ({
      ...dataset,
      label: dataset.label.slice(startIndex, endIndex),
      data: dataset.data.slice(startIndex, endIndex),
      backgroundColor: dataset.backgroundColor.slice(startIndex, endIndex),
    }));
    
    let minAxisVal = 0;
    let maxAxisVal = 0;
    if (chartType === 'scatter') {
        minAxisVal = Math.min(...displayedDatasets.flatMap(dataset => dataset.data.map(point => Math.min(point.x, point.y))));
        minAxisVal = Math.max(Math.round(minAxisVal-5, 0), 0);

        maxAxisVal = Math.max(...displayedDatasets.flatMap(dataset => dataset.data.map(point => Math.max(point.x, point.y))));
        maxAxisVal = Math.max(Math.round(maxAxisVal, 0), 0);
    } 

    return [{
      ...data,
      labels: displayedLabels,
      datasets: displayedDatasets,
    }, 
    minAxisVal,
    maxAxisVal];
  };


//  Main function
const BaseChart = ({ chartType, theme, chartData, chartRef, updateChartData, labelsPerPage, selectedPosition, setSelectedPosition, setSelectedUser, config }) => {
  const canvasRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(0);
  const managerToggleState = localStorage.getItem('managerToggleState');
  const url = window.location.href;
  const parts = url.split('/');
  const page = '/' + parts[parts.length-1];

  const displayedDataInfo = useMemo(() => getDisplayedData(chartData, currentPage, labelsPerPage, chartType), [chartData, currentPage, labelsPerPage, chartType]);
  const [displayedData, setDisplayedData] = useState(displayedDataInfo[0]);
  const [minVal, setMinVal] = useState(displayedDataInfo[1]);
  const [maxVal, setMaxVal] = useState(displayedDataInfo[2]);

  // Handle click event
  const handleChartClick = (e) => {
    const elements = chartRef.current.getElementsAtEventForMode(e, 'nearest', { intersect: true }, true);
    
    if (elements.length) {
      const { index } = elements[0];  // Get index of data point, not dataset
      const label = chartRef.current.data.labels[index];
      setSelectedUser(label);
    }
  };

  useEffect(() => {
    setDisplayedData(displayedDataInfo[0])
    setMinVal(displayedDataInfo[1]);
    setMaxVal(displayedDataInfo[2]);
  }, [displayedDataInfo]);
  
  useEffect(() => {
    
    if (chartRef.current) {
      updateChartData(chartRef.current, displayedData);

      chartRef.current.options = {
        ...chartRef.current.options,
        layout: {padding: {
          top: 30,
          bottom: 30
        }},

        scales: {
          x: {
            ...chartRef.current.options.scales.x,
            min: chartType === 'scatter' ? minVal : undefined,
            ticks: {
              ...chartRef.current.options.scales.x.ticks,
              color: theme === 'light' ? 'black' : 'white',
              ...config.xTicks // Need to figure this out
            },
            title: {
                color: theme === 'light' ? 'black' : 'white',
                ...config.xTitle 
              }
          },
          y: {
            ...chartRef.current.options.scales.y,
            min: chartType === 'scatter' ? minVal : undefined,
            ticks: {
              ...chartRef.current.options.scales.y.ticks,
              color: theme === 'light' ? 'black' : 'white',
              ...config.yTicks
            },
            
            grid: {
              ...chartRef.current.options.scales.y.grid,
              color: theme === 'light' ? 'rgba(0, 0, 0, 0.1)' : 'rgba(255, 255, 255, 0.1)',
            },
            title: {
                color: theme === 'light' ? 'black' : 'white',
                ...config.yTitle
              }
          },
          ...config.scales
        },
        plugins: {
          ...chartRef.current.options.plugins,
          legend: {
            ...config.legend,
            ...chartRef.current.options.plugins.legend,
            labels: {
              ...chartRef.current.options.plugins.legend.labels,
              color: theme === 'light' ? 'black' : 'white',
            },
          },
          datalabels: {
            color: theme === 'light' ? 'black' : 'white',
            ...config.dataLabels
          },
          annotation: {
            annotations: {
            line1: {
                type: 'line',
                yMin: chartType === 'scatter' ? minVal : 0,
                yMax: chartType === 'scatter' ? maxVal : 0,  // Adjust this value based on your chart's y-axis
                xMin: chartType === 'scatter' ? minVal : 0,
                xMax: chartType === 'scatter' ? maxVal : 0,  // Adjust this value based on your chart's x-axis
                borderColor: theme === 'light' ? 'rgba(128, 128, 128, 0.3)' : 'rgba(255, 255, 255, 0.3)',
                borderWidth: 2,
                borderDash: [10, 10],
             },
            },
          },
        },
        tooltips: {
          ...config.toolTips
        },
        
      };
      chartRef.current.update();

    // Otherwise, render a new chart
    } else {
      const myChartRef = canvasRef.current.getContext('2d');
      const newChartInstance = new ChartJS(myChartRef, {
        type: chartType,
        data: displayedData,
        options: {
            onClick: handleChartClick,
            layout: {padding: {
                top: 30,
                bottom: 30
              }},
              scales: {
                x: {
                    min: chartType === 'scatter' ? minVal : undefined,
                  grid: {
                    display: false
                  },
                  ticks: {
                    color: theme === 'light' ? 'black' : 'white', 
                    
                  },
                  title: {
                    color: theme === 'light' ? 'black' : 'white',
                    ...config.xTitle
                  }
                },
                y: {
                    min: chartType === 'scatter' ? minVal : undefined,
                  grid: {
                    color: theme === 'light' ? 'rgba(0, 0, 0, 0.1)' : 'rgba(255, 255, 255, 0.1)',
                  },
                  ticks: {
                    color: theme === 'light' ? 'black' : 'white',
                  },
                  title: {
                    color: theme === 'light' ? 'black' : 'white',
                    ...config.yTitle
                  } 
                },
              },
              responsive: true,
              maintainAspectRatio: false,
          plugins: {
            legend: {
              ...config.legend,
                labels: {
                  color: theme === 'light' ? 'black' : 'white', 
                },
              },
            datalabels: {
              color: theme === 'light' ? 'black' : 'white',
              ...config.dataLabels, // Apply custom configuration here
            },
          },
        },
      });
      chartRef.current = newChartInstance;
    }
  }, [displayedData, chartRef, updateChartData, theme, config]);

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(Math.max(currentPage - 1, 0));
  };

  const handlePositionClick = (value) => {
    setSelectedPosition(value);
    setCurrentPage(0);
  };

  return (
    <>
      <canvas id={`${chartType}Chart`} ref={canvasRef} />
      <div className='button-container'>
        {/* Page buttons */}
        <div className='navigation-buttons'>
          {chartData.labels.length > labelsPerPage ? (
            <>
              <button onClick={prevPage} disabled={currentPage === 0} className={`nav-button ${currentPage === 0 ? 'disabled': ''}`}>&larr;</button>
              <button onClick={nextPage} disabled={(currentPage + 1) * labelsPerPage >= chartData.labels.length} className={`nav-button ${(currentPage + 1) * labelsPerPage >= chartData.labels.length ? 'disabled': ''}`}>&rarr;</button>
            </>
          ) : <>
          <button onClick={prevPage} className='nav-button-hidden'>&larr;</button>
          <button onClick={nextPage} className='nav-button-hidden'>&rarr;</button>
          </> }
        </div>
  
        {/* Position filter buttons */}
        <div className='chart-filters'>
          {managerToggleState === 'true' && page !== '/'? ( 
            ['All', 'QB', 'RB', 'WR', 'TE', 'K', 'D/ST'].map((pos) => (
            <button
              key={pos}
              className={`filter-button ${pos === "D/ST" ? 'D' : pos} ${selectedPosition === pos ? 'active' : ''}`}
              onClick={() => handlePositionClick(pos)}
            >
              {pos}
            </button>
          ))
        ): null }
        </div>
      </div>
    </>
  );
};

export default BaseChart;
