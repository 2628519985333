import React, { useState, useRef } from "react";
import ChartPageLayout from "../components/Layout/ChartPageLayout";

const BenchManagement = () => {
  const refreshOnlyOnClickState = (localStorage.getItem('refreshOnlyOnClick') ?? true);

  const positionColors = {
    "QB": "#fa8174",
    "RB": "#fac274",
    "WR": "#fae474",
    "TE": "#74fa9c",
    "K": "#81b1d2",
    "D/ST": "#e474fa"
  };

  // Chart variables
  const [chartData1, setChartData1] = useState({labels: [], datasets: [{label: [], data: [], backgroundColor: []},]});
  const [chartData2, setChartData2] = useState({labels: [], datasets: [{label: [], data: [], backgroundColor: []},]});
  const [chartData3, setChartData3] = useState({labels: [], datasets: [{label: [], data: [], backgroundColor: []},]});
  const chartRef1 = useRef(null);
  const chartRef2 = useRef(null);
  const chartRef3 = useRef(null);
  const [descriptionText1, setDescriptionText1] = useState('')
  const [descriptionText2, setDescriptionText2] = useState('')
  const [descriptionText3, setDescriptionText3] = useState('')
  const [isLoading1, setIsLoading1] = useState(false)
  const [isLoading2, setIsLoading2] = useState(false)
  const [isLoading3, setIsLoading3] = useState(false)
  const [waiting1, setWaiting1] = useState(false)
  const [waiting2, setWaiting2] = useState(false)
  const [waiting3, setWaiting3] = useState(false)

  // Configure charts
  const chartConfigs = [
    { type: 'bar', data: chartData1, setData: setChartData1, chartRef: chartRef1, id:1, title: 'Points Left on Bench', 
    descriptionText: descriptionText1, setDescriptionText: setDescriptionText1, isLoading: isLoading1, setIsLoading: setIsLoading1, waiting: waiting1, setWaiting: setWaiting1 },
    { type: 'bar', data: chartData2, setData: setChartData2, chartRef: chartRef2, id:2, title: 'Draft Pick Points', 
    descriptionText: descriptionText2, setDescriptionText: setDescriptionText2, isLoading: isLoading2, setIsLoading: setIsLoading2, waiting: waiting2, setWaiting: setWaiting2 },
    { type: 'bar', data: chartData3, setData: setChartData3, chartRef: chartRef3, id:3, title: 'Non-Draft Pick Points', 
    descriptionText: descriptionText3, setDescriptionText: setDescriptionText3, isLoading: isLoading3, setIsLoading: setIsLoading3, waiting: waiting3, setWaiting: setWaiting3 },
    // Add more chart configurations as necessary
  ];
 
  // Fetch data - 1 api call per chart
  const fetchDataFunctions = [
    () => fetch(`${process.env.REACT_APP_BACKEND_URL}/bench_efficiency`).then(response => response.json()),
    () => fetch(`${process.env.REACT_APP_BACKEND_URL}/draft_picks`).then(response => response.json()),
    () => fetch(`${process.env.REACT_APP_BACKEND_URL}/non_draft_picks`).then(response => response.json())
  ];


  // Declare individual functions for each chart
  const getDataChart1 = (filteredData, managerToggle, userName) => {
    const groupedData = {};
    const playerPositions = {};
  
    filteredData.forEach((item) => {
      const { player, pos, score_diff, team } = item;
      const groupKey = managerToggle ? player : team;
  
      if (!groupedData[groupKey]) {
        groupedData[groupKey] = 0;
        if (managerToggle) playerPositions[player] = pos;
      }
  
      groupedData[groupKey] += score_diff;
    });
  
    const sortedData = Object.entries(groupedData).sort((a, b) => b[1] - a[1]);
  
    const backgroundColors = sortedData.map(([key, value]) => {
      let color = value >= 0 ? "#81b1d2" : "#fa8174";
      color = key === userName ? "#74fa9c" : color;
      return managerToggle ? positionColors[playerPositions[key]] : color;
    });
  
    return {
      labels: sortedData.map(([key, _]) => key),
      datasets: [{
        label: 'Score',
        data: sortedData.map(([_, value]) => value),
        backgroundColor: backgroundColors,
        borderWidth: 1,
      }],
    };
  }


  // Put transformations in an array, corresponding to each api call and chart
  const transformFunctions = [
    (filteredData, managerToggle, selectedUser, userName) => {
        return getDataChart1(filteredData, managerToggle, userName)
    },
    (filteredData, managerToggle, selectedUser, userName) => {
        return getDataChart1(filteredData, managerToggle, userName)
    },
    (filteredData, managerToggle, selectedUser, userName) => {
        return getDataChart1(filteredData, managerToggle, userName)
    }
  ];

  // Grab the latest description from the database or create a new description 
  const retrieveDescription = async (chartId, selectedSeason, selectedWeek, selectedPosition, managerToggle, selectedUser, activePage, userName) => {
    const chartToUpdate = chartConfigs.find(chart => chart.id === chartId);
    chartToUpdate.setIsLoading(true); // Start loading when button is clicked
    chartToUpdate.setWaiting(true);
    
    let response;
    try {
      const filters = { season: selectedSeason, week: selectedWeek, position: selectedPosition, manager: managerToggle, lineChartName: selectedUser, page: activePage};
      response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/retrieve_description?chartId=${chartId}&userName=${userName}&filters=${JSON.stringify(filters)}`);

      // Checking if the content type indicates a stream
      if ((response.headers.get('Content-Type').includes('text/event-stream')) && (refreshOnlyOnClickState === 'false')) {
          // Handle streaming here
          const eventSource = new EventSource(response.url);

          let accumulatedData = '';
          eventSource.onmessage = (event) => {
              chartToUpdate.setDescriptionText(event.data);
              chartToUpdate.setWaiting(false);
          };

          eventSource.onerror = (error) => {
              console.error("Error in EventSource:", error);
              eventSource.close();
          };

          eventSource.onopen = (event) => {
              chartToUpdate.setIsLoading(false);
          };

          return;
      }

      // If not streaming, then handle as regular JSON response
      if (refreshOnlyOnClickState === 'false') {
        const newDescription = await response.json();
        if (chartToUpdate && chartToUpdate.setDescriptionText) {
          chartToUpdate.setDescriptionText(newDescription);
        } else {
          console.error(`Chart with ID ${chartId} not found or it has no setDescriptionText function.`);
        }
      }

      } catch (error) {
        console.error("Error refreshing description:", error);
      } finally {
        if (!response.headers.get('Content-Type').includes('text/event-stream')) {
          chartToUpdate.setWaiting(false);
          chartToUpdate.setIsLoading(false); // Stop loading if it's not a stream
        }
      }
      chartToUpdate.setWaiting(false);
      chartToUpdate.setIsLoading(false);
    }


  return (
    <div className="benchmanagement">
        <ChartPageLayout fetchDataFunctions={fetchDataFunctions} transformFunctions={transformFunctions} chartConfigs={chartConfigs} 
                         activePage={'bench_management'} pageTitle={'Bench Management'} retrieveDescription={retrieveDescription}
        >
        </ChartPageLayout>
    </div>
    );
};

export default BenchManagement;

