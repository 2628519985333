import React, { useEffect, useRef, useState, useContext } from "react";
import { ThemeContext } from '../../theme-context';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import { AiOutlineUser } from 'react-icons/ai';
import { FiLogOut } from 'react-icons/fi';
import { MdExpandMore } from 'react-icons/md';
import Modal from 'react-modal';
import Switch from 'react-switch';
import './Header.css';

function Header({ navbarOpen, setNavbarOpen }) {
    const { theme } = useContext(ThemeContext);
    const [userName, setUserName] = useState('');
    const [userInitials, setUserInitials] = useState('');
    const [userDropdownOpen, setUserDropdownOpen] = useState(false);
    const [flipIcon, setFlipIcon] = useState(false);
    const navigate = useNavigate();
    const headerRef = useRef(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [refreshOnlyOnClick, setRefreshOnlyOnClick] = useState(JSON.parse(localStorage.getItem('refreshOnlyOnClick')) ?? true);

    // Toggle navbar open and closed
    const toggleNavbar = () => {
      setNavbarOpen(!navbarOpen);
      document.body.classList.toggle('open-navbar', !navbarOpen);
      document.body.classList.toggle('close-navbar', navbarOpen);
      localStorage.setItem('navbarState', JSON.stringify(!navbarOpen)); // Save the navbar state to local storage
    };

    // Toggle user drop down open and closed
    const toggleUserDropDown = () => {
      setUserDropdownOpen(!userDropdownOpen);
      setFlipIcon(!flipIcon);
    };

    // Function to toggle the modal
    const toggleModal = () => {
      setModalIsOpen(!modalIsOpen);
    };

    // Function to toggle Simulate Typing
    const toggleRefreshOnlyOnClick = () => {
      setRefreshOnlyOnClick(!refreshOnlyOnClick);
      localStorage.setItem('refreshOnlyOnClick', JSON.stringify(!refreshOnlyOnClick)); // 3. Updating local storage when the toggle button is clicked
    };

    // Close user drop down if user clicks outside
    useEffect(() => {
        function handleClickOutside(event) {
            if (headerRef.current && !headerRef.current.contains(event.target)) {
                setUserDropdownOpen(false);
                setFlipIcon(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [headerRef]);

    // Get user name and initials from local storage
    useEffect(() => {
        const userName = localStorage.getItem('userName');
        setUserName(userName.toString());
    }, []);

    useEffect(() => {
        const userInitials = localStorage.getItem('userInitials');
        setUserInitials(userInitials.toString());
    }, []);

    // Function to log out user my removing session
    function handleLogout() {
        Cookies.remove('sessionId');
        navigate('/login');
    }

return (
    <header className={`header ${theme === 'light' ? '': 'dark'}`} ref={headerRef}>

      <div className="hamburger-logo">
        <FaBars className={`hamburger ${theme === 'light' ? '': 'dark'}`} onClick={toggleNavbar}/>
        <a href="/" className="logo-link">
          <img src="logo.png" alt="WebApp Logo" className="logo" />
        </a>
      </div>

      <div className="user-info" onClick={toggleUserDropDown}>
        <div className="user-initials">{userInitials}</div>
        <div className="user-name">{userName}</div>
        <div className={`rotate-icon ${flipIcon ? 'rotate' : ''}`}>
          <MdExpandMore />
        </div>
        <div className={`user-dropdown ${userDropdownOpen ? "open" : ""}`}>
          <div className="user-dropdown-content">
            <a href="#" onClick={toggleModal}> 
              <AiOutlineUser className="user-dropdown-icon" />
              <span className="user-dropdown-text">User Settings</span>
            </a>
            <a href="#" onClick={handleLogout}>
              <FiLogOut className="user-dropdown-icon" />
              <span className="user-dropdown-text">Sign Out</span>
            </a>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={toggleModal}
        ariaHideApp={false}
        style={{
            overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                backdropFilter: 'blur(5px)',
            },
            content: {
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                height: '200px',
                width: '400px',
                background: theme === 'dark' ? 'rgb(52,53,65)' : '#fff',
                color: theme === 'dark' ? 'rgba(197,197,210)' : 'initial',
                borderColor: 'transparent',
                overflow: 'auto',
                WebkitOverflowScrolling: 'touch',
                borderRadius: '20px',
                outline: 'none',
                padding: '20px',
            }
          }}
        >
          <h2 className="modal-title">User Settings</h2>
          <div>
            <label htmlFor="refresh-onclick" className="toggle-label">
                Only Generate New Text When Button is Clicked
            </label>
            <Switch
                id="refresh-onclick"
                onChange={toggleRefreshOnlyOnClick}
                checked={refreshOnlyOnClick}
                onColor="#4CAF50"
                offColor="#ccc"
                uncheckedIcon={false}
                checkedIcon={true}
                width={40}
                height={20}
                handleDiameter={12}
                borderRadius={20}
            />
            </div>
        </Modal>
        </header>
    );
}

export default Header;
