import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import SignupPage from "./pages/SignupPage";
import VerificationPage from "./pages/VerificationPage";
import VerificationRedirectPage from "./pages/VerificationRedirectPage";
import WelcomePage from './pages/WelcomePage';
import HomePage from "./pages/HomePage";
import ActualvsProjected from "./pages/ActualvsProjected";
import BenchManagement from "./pages/BenchManagement";
import StrengthOfSchedule from "./pages/StrengthOfSchedule";
import PublicRoute from './components/Routing/PublicRoute';
import PrivateRoute from './components/Routing/PrivateRoute';
import MainLayout from './components/Layout/MainLayout';
import { ThemeProvider } from './theme-provider';

function App() {

  return (
    <Router>
      <Routes>
        <Route exact path='/login' element={<PublicRoute/>}>
          <Route exact path='/login' element={<LoginPage/>}/>
        </Route>

        <Route exact path='/signup' element={<PublicRoute/>}>
          <Route exact path='/signup' element={<SignupPage/>}/>
        </Route>

        <Route exact path='/verification' element={<PublicRoute/>}>
          <Route exact path='/verification' element={<VerificationPage/>}/>
        </Route>
        
        <Route path="/verify" element={<VerificationRedirectPage />} />

        <Route exact path='/welcome' element={<PrivateRoute/>}>
          <Route exact path='/welcome' element={<WelcomePage/>}/>
        </Route>

        <Route
          element={
              <ThemeProvider>
                <PrivateRoute />
              </ThemeProvider>
            }
        >
          <Route
            element={
              <MainLayout>
                <PrivateRoute />
              </MainLayout>
            }
          >
            <Route exact path='/' element={<HomePage/>}/>
            <Route exact path='/actual_vs_projected' element={<ActualvsProjected/>}/>
            <Route exact path='/bench_management' element={<BenchManagement/>}/>
            <Route exact path='/strength_of_schedule' element={<StrengthOfSchedule/>}/>
            {/* Add other routes that should include the Header and NavBar components here */}
          </Route>
        </Route>
        
      </Routes>
    </Router>
  );
}

export default App;
