import React, { useEffect, useState, useRef, useContext } from "react";
import HomePageLayout from "../components/Layout/HomePageLayout";

const HomePage = () => {
  const refreshOnlyOnClickState = (localStorage.getItem('refreshOnlyOnClick') ?? true);

  // Chart variables
  const [chartData1, setChartData1] = useState({labels: [], datasets: [{label: [], data: [], backgroundColor: []},]});
  const [chartData2, setChartData2] = useState({labels: [], datasets: [{label: [], data: [], backgroundColor: []},]});
  const chartRef1 = useRef(null);
  const chartRef2 = useRef(null);
  const [descriptionText1, setDescriptionText1] = useState('')
  const [descriptionText2, setDescriptionText2] = useState('')
  const [isLoading1, setIsLoading1] = useState(false)
  const [isLoading2, setIsLoading2] = useState(false)
  const [waiting1, setWaiting1] = useState(false)
  const [waiting2, setWaiting2] = useState(false)

  // Configure charts
  const chartConfigs = [
    { type: 'doughnut', data: chartData1, setData: setChartData1, chartRef: chartRef1, id:1, title: 'Lineup Optimization', 
    descriptionText: descriptionText1, setDescriptionText: setDescriptionText1, isLoading: isLoading1, setIsLoading: setIsLoading1, waiting: waiting1, setWaiting: setWaiting1 },
    { type: 'radar', data: chartData2, setData: setChartData2, chartRef: chartRef2, id:2, title: 'Lineup Optimization by Position', 
    descriptionText: descriptionText2, setDescriptionText: setDescriptionText2, isLoading: isLoading2, setIsLoading: setIsLoading2, waiting: waiting2, setWaiting: setWaiting2 },
    // Add more chart configurations as necessary
  ];
 
  // Fetch data - 1 api call per chart
  const fetchDataFunctions = [
    () => fetch(`${process.env.REACT_APP_BACKEND_URL}/lineup_optimization`).then(response => response.json()),
    () => fetch(`${process.env.REACT_APP_BACKEND_URL}/lineup_optimization`).then(response => response.json()),
  ];

  // Declare individual functions for each chart
  const getDataChart1 = (filteredData, managerToggle, userName) => {
    const efficiencyTypes = managerToggle ? ['roster_actual', 'all_actual'] : ['roster_projected', 'all_projected'];
    const datasets = efficiencyTypes.map(type => {
        const filteredDataByUser = filteredData.filter(item => item.team === userName);
        const filteredDataByType = filteredDataByUser.filter(item => item.efficiency_type === type);
        const totalScore = filteredDataByType.reduce((total, item) => total + (managerToggle ? item.actual_score : item.projected_score), 0);
        const totalNewScore = filteredDataByType.reduce((total, item) => total + (managerToggle ? item.new_actual_score : item.new_projected_score), 0);
        const percentage = (totalScore / totalNewScore) * 100;
        return {
            data: [type.includes('roster') ? percentage.toFixed(2): 0, type.includes('roster') ? (100 - percentage).toFixed(2) : 0, 
            type.includes('roster') ? 0: percentage.toFixed(2), type.includes('roster') ? 0 : (100 - percentage).toFixed(2)],
            backgroundColor: ['#81b1d2', 'transparent', '#fa8174', 'transparent'],
            borderColor: ['transparent', 'transparent', 'transparent', 'transparent'],
            label: '% of Points Captured'
        };
      });

    return {
      labels: ['Roster / Bench Only', '', 'Waiver Wires', ''],
      datasets: datasets,
    };
  }
  
  const getDataChart2 = (filteredData, managerToggle, userName) => {
    const efficiencyTypes = managerToggle ? ['roster_actual', 'all_actual'] : ['roster_projected', 'all_projected'];
    const positions = ['QB', 'RB', 'WR', 'TE', 'K', 'D/ST', 'Flex'];
    const datasets = efficiencyTypes.map((type, index) => {
        const filteredDataByUser = filteredData.filter(item => item.team === userName);
        const chartData = positions.map(position => {
            const filteredDataByPosition = filteredDataByUser.filter(item => item.pos === position && item.efficiency_type === type);
            const totalScore = filteredDataByPosition.reduce((total, item) => total + (managerToggle ? item.actual_score : item.projected_score), 0);
            const totalNewScore = filteredDataByPosition.reduce((total, item) => total + (managerToggle ? item.new_actual_score : item.new_projected_score), 0);
            return ((totalScore / totalNewScore) * 100).toFixed(2);
        });

        const colors = [
            { background: 'rgba(129, 177, 210, 0.2)', border: '#81b1d2', point: 'rgba(129, 177, 210, 1)' },
            { background: 'rgba(250, 129, 116, 0.2)', border: '#fa8174', point: 'rgba(250, 129, 116, 1)' },
        ];
        
          return {
            data: chartData,
            label: type.includes('roster') ? '% of Points Captured': '% of Points Captured',
            backgroundColor: colors[index].background,
            borderColor: colors[index].border,
            pointBackgroundColor: colors[index].point,
          };
        });

        return {
            labels: positions,
            datasets: datasets,
        };
    }

    // Put transformations in an array, corresponding to each api call and chart
    const transformFunctions = [
        (filteredData, managerToggle, selectedUser, userName) => {
            return getDataChart1(filteredData, managerToggle, userName)
        },
        (filteredData, managerToggle, selectedUser, userName) => {
            return getDataChart2(filteredData, managerToggle, userName)
        }
    ];
        
    const transformTableData = (filteredDataByUser) => {
      let data = filteredDataByUser.reduce((acc, cur) => {
          const existingEntry = acc.find(entry => entry.pos === cur.pos && entry.player === cur.player);
  
          if (existingEntry) {
              if ((cur.efficiency_type === 'roster_actual') || (cur.efficiency_type === 'roster_projected')) {
                  existingEntry.new_player_roster = cur.new_player;
                  existingEntry.new_actual_score_roster = cur.new_actual_score.toFixed(1);
                  existingEntry.new_projected_score_roster = cur.new_projected_score.toFixed(1);
              } else if ((cur.efficiency_type === 'all_actual') || (cur.efficiency_type === 'all_projected')) {
                  existingEntry.new_player_all = cur.new_player;
                  existingEntry.new_actual_score_all = cur.new_actual_score.toFixed(1);
                  existingEntry.new_projected_score_all = cur.new_projected_score.toFixed(1);
              }
          } else {
              const newEntry = {
                  pos: cur.pos,
                  player: cur.player,
                  actual_score: cur.actual_score.toFixed(1),
                  projected_score: cur.projected_score.toFixed(1),
              };
  
              if ((cur.efficiency_type === 'roster_actual') || (cur.efficiency_type === 'roster_projected')) {
                  newEntry.new_player_roster = cur.new_player;
                  newEntry.new_actual_score_roster = cur.new_actual_score.toFixed(1);
                  newEntry.new_projected_score_roster = cur.new_projected_score.toFixed(1);
              } else if ((cur.efficiency_type === 'all_actual') || (cur.efficiency_type === 'all_projected')) {
                  newEntry.new_player_all = cur.new_player;
                  newEntry.new_actual_score_all = cur.new_actual_score.toFixed(1);
                  newEntry.new_projected_score_all = cur.new_projected_score.toFixed(1);
              }
  
              acc.push(newEntry);
          }
  
          return acc;
      }, []);
  
      // Create a total row
      let totalRow = {
          pos: "Total",
          player: "",
          actual_score: data.reduce((total, item) => total + Number(item.actual_score), 0).toFixed(1),
          projected_score: data.reduce((total, item) => total + Number(item.projected_score), 0).toFixed(1),
          new_player_roster: "",
          new_actual_score_roster: data.reduce((total, item) => total + Number(item.new_actual_score_roster || 0), 0).toFixed(1),
          new_projected_score_roster: data.reduce((total, item) => total + Number(item.new_projected_score_roster || 0), 0).toFixed(1),
          new_player_all: "",
          new_actual_score_all: data.reduce((total, item) => total + Number(item.new_actual_score_all || 0), 0).toFixed(1),
          new_projected_score_all: data.reduce((total, item) => total + Number(item.new_projected_score_all || 0), 0).toFixed(1),
      }
  
      data.push(totalRow);
      
      return data;
  }

  // Grab the latest description from the database or create a new description 
  const retrieveDescription = async (chartId, selectedSeason, selectedWeek, selectedPosition, managerToggle, selectedUser, activePage, userName) => {
    const chartToUpdate = chartConfigs.find(chart => chart.id === chartId);
    chartToUpdate.setIsLoading(true); // Start loading when button is clicked
    chartToUpdate.setWaiting(true);
    
    let response;
    try {
      const filters = { season: selectedSeason, week: selectedWeek, position: selectedPosition, manager: managerToggle, lineChartName: selectedUser, page: activePage};
      response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/retrieve_description?chartId=${chartId}&userName=${userName}&filters=${JSON.stringify(filters)}`);

      // Checking if the content type indicates a stream
      if ((response.headers.get('Content-Type').includes('text/event-stream')) && (refreshOnlyOnClickState === 'false')) {
          console.log(response.headers.get('Content-Type'))
          // Handle streaming here
          const eventSource = new EventSource(response.url);

          eventSource.onmessage = (event) => {
              // console.log(event)
              chartToUpdate.setDescriptionText(event.data);
              chartToUpdate.setWaiting(false);
          };

          eventSource.onerror = (error) => {
              console.error("Error in EventSource:", error);
              eventSource.close();
          };

          eventSource.onopen = (event) => {
              chartToUpdate.setIsLoading(false);
          };

          return;
      }

      // If not streaming, then handle as regular JSON response
      if (refreshOnlyOnClickState === 'false') {
        const newDescription = await response.json();
        if (chartToUpdate && chartToUpdate.setDescriptionText) {
          chartToUpdate.setDescriptionText(newDescription);
        } else {
          console.error(`Chart with ID ${chartId} not found or it has no setDescriptionText function.`);
        }
      }

      } catch (error) {
        console.error("Error refreshing description:", error);
      } finally {
        if (!response.headers.get('Content-Type').includes('text/event-stream')) {
          chartToUpdate.setWaiting(false);
          chartToUpdate.setIsLoading(false); // Stop loading if it's not a stream
        }
      }
      chartToUpdate.setWaiting(false);
      chartToUpdate.setIsLoading(false);
    }

  return (
    <div className="home-page">
        <HomePageLayout fetchDataFunctions={fetchDataFunctions} transformFunctions={transformFunctions} chartConfigs={chartConfigs} 
                        activePage={'home'} pageTitle={'Lineup Optimization'} transformTableData={transformTableData} retrieveDescription={retrieveDescription}
        >
        </HomePageLayout>
    </div>
    );
};

export default HomePage;
