import React from 'react';
import { Link } from 'react-router-dom';
import Confetti from 'react-confetti';
import "../components/LoginForm/LoginForm.css";

const WelcomePage = () => {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            textAlign: 'center'
        }}>
            <Confetti numberOfPieces={200} />
            <h1 style={{ fontSize: '3em' }}>Welcome</h1>
            <Link to="/" className="login-signup-button">
                Enter
            </Link>
        </div>
    );
}

export default WelcomePage;
