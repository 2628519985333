// RadarChart.js
import BaseChart from './BaseChart';

const RadarChart = (props) => {
  const config = {
    dataLabels: {
        formatter: function(value, data) {
            return `${value}%`;
        },
        display: false,
    },
    legend: {
        display: false,
    },
    scales: {
        x: {
            display: false,
        },
        y: {
            display: false,
        },
        r: {
            pointLabels: {
                display: true, // Hides the labels around the radar chart
                color: props.theme === 'light' ? 'black' : 'white',
            },
            ticks: {
                display: false // Hides the labels in the middle (numbers)
            },
            grid: {
                color: props.theme === 'light' ? 'rgba(0, 0, 0, 0.1)' : 'rgba(255, 255, 255, 0.1)',
            },
            angleLines: {
                color: props.theme === 'light' ? 'rgba(0, 0, 0, 0.1)' : 'rgba(255, 255, 255, 0.1)',
            }
        },
        
        // gridLines: {
        //     color: props.theme === 'light' ? 'rgba(0, 0, 0, 0.1)' : 'rgba(255, 255, 255, 0.1)',
        // },
    },
  };

  return <BaseChart {...props} chartType="radar" config={config} />;
};

export default RadarChart;
