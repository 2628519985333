import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Cookies from 'js-cookie';

function PrivateRoute({ component: Component, ...rest }) {
  const isLoggedIn = Cookies.get('sessionId') !== undefined;

  return isLoggedIn ? <Outlet /> : <Navigate to="/login" />;
}

export default PrivateRoute;
