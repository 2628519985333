import React, { useState } from "react";
import SignupForm from "../components/SignupForm/SignupForm";
import Cookies from 'js-cookie';
import { useNavigate  } from 'react-router-dom';

function SignupPage() {
  const navigate = useNavigate ();
  const [errorMessage, setErrorMessage] = useState('');

  const handleSignup = (email, password) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/signup_submit`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, password }),
        });
  
        if (response.status === 403) {
          const data = await response.json();
          navigate('/verification');
          resolve();
        } else if (!response.ok) {
          const data = await response.json();
          throw new Error(data.error);
        } else {
          const data = await response.json();
          console.log('Data received:', data);
          Cookies.set('sessionId', data.sessionId, { expires: 30 });
          navigate('/');
          resolve();
        }
      } catch (error) {
        console.error(error);
        setErrorMessage(error.message);
        Cookies.remove('sessionId');
        reject(error);
      }
    });
  };

  return (
    <div className="login-page"><SignupForm onSubmit={handleSignup} errorMessage={errorMessage} /></div>
  );
}

export default SignupPage;