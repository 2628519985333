import React, { useEffect, useState, useRef, useContext } from "react";
import { ThemeContext } from '../../theme-context';
import Switch from 'react-switch';
import { MdExpandMore } from 'react-icons/md';
import './FilterSection.css';

const FilterSection = ( {selectedSeason, setSelectedSeason, selectedWeek, setSelectedWeek, managerToggle, setManagerToggle, activePage, pageTitle} ) => {
  const { theme } = useContext(ThemeContext);
  const [isSticky, setIsSticky] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [flipIcon, setFlipIcon] = useState(false);
  const dropdownRef = useRef(null);
  const [upperBound, setUpperBound] = useState(18);

  // manager and week filters
  const handleManagerToggle = () => {
    setManagerToggle((prevState) => !prevState);
    localStorage.setItem('managerToggleState', JSON.stringify(!managerToggle));
  };

  const handleSeasonClick = (value) => {
    setSelectedSeason(value);
    localStorage.setItem('selectedSeasonState', JSON.stringify(value));
  };

  const handleWeekChange = (value) => {
    setSelectedWeek(value);
    toggleDropDown();
    localStorage.setItem('selectedWeekState', JSON.stringify(value));
  };

  // Toggle dropdown open and closed
  const toggleDropDown = () => {
    setDropdownOpen(!dropdownOpen);
    setFlipIcon(!flipIcon);
  };

  useEffect(() => {
    if (activePage === 'strength_of_schedule') {
      setUpperBound(14);
    } else {
      setUpperBound(18);
    }
  }, [activePage]);

  // Create the dropdown options
  const renderDropdownOptions = () => {
    const options = [];
    if (activePage !== 'home') {
      options.push(
        <div key={0} className="dropdown-option" onClick={() => handleWeekChange("All")}>
          All
        </div>
      );
    }
    
    for (let i = 1; i <= upperBound; i++) {
      options.push(
        <div key={i} className="dropdown-option" onClick={() => handleWeekChange(i)}>
          {i}
        </div>
      );
    }
    return options;
  };

  // Close the dropdown if there is a click outside of it
  useEffect(() => {
    function handleClickOutside(event) {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setDropdownOpen(false);
          setFlipIcon(false);
        }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
        document.removeEventListener("mousedown", handleClickOutside);
    };
}, [dropdownRef]);

  // Change size of filter section on scroll
  const handleScroll = () => {
    const filterSection = document.getElementById('filter-section');
    const filterSectionRect = filterSection.getBoundingClientRect();
    const filterSectionBottom = filterSectionRect.bottom;
    const pageContent = document.getElementById('page-content');
    const pageContentRect = pageContent.getBoundingClientRect();
    const pageContentTop = pageContentRect.top;
    const scrolled = window.pageYOffset > 0;
    if (scrolled) {
      setIsSticky(true);
      setIsScrolled(true);
    } else {
      setIsSticky(false);
      setIsScrolled(false);
    }
    if (filterSectionBottom >= pageContentTop) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div id="filter-section" className={`${isSticky ? 'sticky' : ''} ${isScrolled ? 'scrolled' : ''} ${theme === 'light' ? '': 'dark'}`}>
      <div id="filter-content" className={`${isSticky ? 'sticky' : ''} ${isScrolled ? 'scrolled' : ''}`}>

        {/* Header */}
        <div className="filter-row">
          <div id="filter-header" className={`${isSticky ? 'sticky' : ''} ${isScrolled ? 'scrolled' : ''}`}>
            <h3>{pageTitle}</h3>
          </div>
        </div>
        <div className="filter-row">

          {/* Season filter */}
          <div className="filter-col">
            <span className="filter-label">Season:</span>
              {(activePage === 'home' ? [2019, 2020, 2021, 2022, 2023] : ['All', 2019, 2020, 2021, 2022, 2023]).map((year) => (
                <button
                  key={year}
                  className={selectedSeason === year ? 'active' : ''}
                  onClick={() => handleSeasonClick(year)}
                >
                  {year}
                </button>
              ))}
          </div>
  
          {/* Manager / Player toggle */}
          {activePage !== 'strength_of_schedule' && (
            <div className="filter-col toggle-col">
              <label htmlFor="toggle-switch" className="toggle-label">
                {activePage === 'home' ? (managerToggle ? 'Optimized for Actual Points Scored' : 'Optimized for Projected Points Scored') : (managerToggle ? 'Players' : 'Managers')}
              </label>
              <Switch
                onChange={handleManagerToggle}
                checked={managerToggle}
                onColor="#4CAF50"
                offColor="#ccc"
                uncheckedIcon={false}
                checkedIcon={false}
                width={40}
                height={20}
                handleDiameter={12}
                borderRadius={20}
              />
            </div>
          )}
        </div>
  
        {/* Week dropdown filter */}
        <div className="filter-row">
          <div className="filter-col">
            <div className="dropdown-selected" onClick={toggleDropDown}>
                Week: 
                <div className='dropdown-text'>{selectedWeek}</div>
                <div className={`rotate-icon ${flipIcon ? 'rotate' : ''}`}>
                  <MdExpandMore />
                </div>
            </div>
            <div className={`custom-dropdown ${dropdownOpen ? "open" : ""}`}>
              <div className="custom-dropdown-content" ref={dropdownRef}>{renderDropdownOptions()}</div>
            </div>
            
          </div>
  
          {/* Nav links to other analytics pages */}
          {activePage !== 'home' && (
          <div className="filter-col nav-links">
            <a href="actual_vs_projected" className={`nav-link ${activePage === 'actual_vs_projected' ? 'active' : ''} ${theme === 'light' ? '': 'dark'}`}>Actual vs Projected Points</a>
            <a href="bench_management" className={`nav-link ${activePage === 'bench_management' ? 'active' : ''} ${theme === 'light' ? '': 'dark'}`}>Bench Management</a>
            <a href="strength_of_schedule" className={`nav-link ${activePage === 'strength_of_schedule' ? 'active' : ''} ${theme === 'light' ? '': 'dark'}`}>Strength of Schedule</a>
          </div>
          )}


        </div>
      </div>
    </div>
  );
};

export default FilterSection;
  