import React, { useContext, useMemo } from 'react';
import { useTable, useColumnOrder, useSortBy } from 'react-table';
import { ThemeContext } from '../../theme-context';
import './HomeCard.css'
import './Table.css'

const HomeTableCard = ({ title, data }) => {
  const { theme } = useContext(ThemeContext);
  const columns = useMemo(
    () => [
      {
        Header: 'Position',
        accessor: 'pos',
      },
      {Header: 'Current Lineup',
      columns: [
        {
            Header: 'Player',
            accessor: 'player',
        },
        {
            Header: 'Projected Score',
            accessor: 'projected_score',
        },
        {
          Header: 'Actual Score',
          accessor: 'actual_score',
        },
        ]
    },
      {
        Header: 'Optimized For Roster',
        columns: [
          {
            Header: 'Player',
            accessor: 'new_player_roster',
          },
          {
            Header: 'Projected Score',
            accessor: 'new_projected_score_roster',
          },
          {
            Header: 'Actual Score',
            accessor: 'new_actual_score_roster',
          },
        ],
      },
      {
        Header: 'Optimized For Waiver Wires',
        columns: [
          {
            Header: 'Player',
            accessor: 'new_player_all',
          },
          {
            Header: 'Projected Score',
            accessor: 'new_projected_score_all',
          },
          {
            Header: 'Actual Score',
            accessor: 'new_actual_score_all',
          },
        ],
      },
    ],
    []
  );
  
  
  
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
    } = useTable({ columns, data }, useSortBy, useColumnOrder);

  return (
    <div className={`home-table-card ${theme === 'light' ? '': 'dark'}`}>
      <div className="home-table-card-title">{title}</div>
      <div className="home-table-card-body">

      <table {...getTableProps()} style={{ width: '100%', margin: '0 auto'}}>

      <thead>
      {headerGroups.map((headerGroup, i) => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column, j) => {
            const headerProps = column.getHeaderProps();
            if ((column.Header === 'Optimized For Roster') || ((i === 1) && (j >= 4) && (j <= 6))) {
              headerProps.className = 'header-roster';
            } else if ((column.Header === 'Optimized For Waiver Wires') || ((i === 1) && (j >= 7))) {
              headerProps.className = 'header-waiver';
            }
            return <th {...headerProps}>{column.render('Header')}</th>
          })}
        </tr>
      ))}
      </thead>

      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          const isTotalRow = i === rows.length - 1;
          const rowClass = isTotalRow ? 'total-row' : 'normal-row';
          return (
            <tr {...row.getRowProps()} className={rowClass}>
              {row.cells.map((cell, cellIndex) => {
                let cellProps = cell.getCellProps();
                if (cellIndex === 0) {
                  cellProps.className = 'index-col';  // apply bold style to the 'Position' column
                } else if (isTotalRow && cellIndex >= 4 && cellIndex <= 6) {
                  cellProps.className = 'total-roster';  // apply blue style to 'Optimized For Roster' totals
                } else if (isTotalRow && cellIndex >= 7) {
                  cellProps.className = 'total-waiver';  // apply red style to 'Optimized For Waiver Wires' totals
                }
                return <td {...cellProps}>{cell.render('Cell')}</td>
              })}
            </tr>
          );
        })}
      </tbody>

    </table>


      </div>
    </div>
  );
};

export default HomeTableCard;
